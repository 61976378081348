.section-title {
  text-align: center;
  font-weight: bolder;
  color: #2f2f2f;  
  font-family: Montserrat,sans-serif;
}

@media only screen and (min-width: 600px) {
  .section-title {
    font-size: 1.5rem;
  }
}

.section-text {
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2f2f2f;
  font-family: Montserrat,sans-serif;
}

.highlight {
  font-weight: bolder; 
}

.content-container {
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1b1b21;
}

.content-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -0px;
  background-image: 
    radial-gradient(circle at top left, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 40%),
    radial-gradient(circle at top right, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 40%);
  background-repeat: no-repeat;
  background-position: top left, top right;
  background-size: 100% 100%, 100% 100%; 
  pointer-events: none; 
}

.content-container .section-title,
.content-container .section-text {
  color: white;
}

.carousel-container, .carousel-text {
  position: relative;
}


.first-section-color {
  color: rgb(200, 213, 224);
}

.text-container {
  flex: 1;
  max-width: 90%;
}

.details-image {
  position: absolute;
  width: 300px;
  height: 450px;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../img/details.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.pilot-content{
  display: flex;
  align-items: center;
  justify-content: center;
}

.pilot-text{
  color: white;
  padding: 0 20px 0 20px;
}
.pilot-button-container{
  padding: 0 20px 0 20px;
  position: relative; /* Add position relative if needed */
}
.pilot-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  
  border: 2px solid gray;
  background-color: rgba(37, 39, 42, 0.7);
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.pilot-button:hover {
  background-color: #502D6D; /* Light background on hover */
  color: #1b1b21; /* Dark text color on hover */
  border-color: #502D6D; /* A purple border on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
}

.common-text-style {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-family: Montserrat, sans-serif;
}

.carousel-and-image-container {
  display: flex;
  justify-content: center; /* This will center the children horizontally */
  align-items: center; /* This will align the children vertically in the middle */
  flex-wrap: wrap; /* Allows children to wrap to the next line as needed */
  gap: 1em; /* Provides space between the carousel and the image */
}

@media only screen and (max-width: 600px) {
  .carousel-and-image-container {
    padding-top: 1rem;
  }
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.carousel-options {
  display: flex;
  justify-content: space-between; /* Aligns carousel text side by side */
  align-items: center; /* Aligns items vertically in the center */
}

.carousel-text {
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 1rem; /* Adjusts spacing between options */
  color: #e0e0e0;  
  width: 100px;
  transition: font-weight 0.2s ease;
  font-family: Montserrat, sans-serif;
  text-align: center; /* Centers the text inside the button */
  white-space: nowrap; /* Prevents text wrapping */
}

.carousel-text.active, .carousel-text:hover {
  color: #FFF;
  font-weight: medium;
}

.carousel-indicators {
  display: flex;
  justify-content: center; /* Aligns dots side by side */
  align-items: center;
}

.dot-container {
  padding: 1rem 3rem; /* Adjusts spacing between dots */
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}

.dot.active {
  background-color: #502D6D;
}

.image-display {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 45%;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .image-display {
    max-width: 100%;
  }
}

.carousel-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;  
  mask-image: radial-gradient(circle, white 80%, transparent 100%);
  max-height: 100%; /* Ensure the image fits within the container without exceeding its size */
  object-fit: cover; /* Cover the container's size while keeping the aspect ratio */
  top: 50%;
  left: 50%;
}

.mask-image-linear {
  -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 70%), linear-gradient(rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(to bottom, transparent, white 10%, white 90%, transparent);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}