/* Scoped CSS for Data Subject Access Form */
.data-access-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  background-color: #1b1b21;
  color: #fff;
  font-family: Montserrat, sans-serif;
  margin-bottom: 50px;
}

.data-access-form-container h1 {
  margin-bottom: 10px;
  text-align: center;
}

.data-access-form-container p {
  margin-bottom: 20px;
  max-width: 90%;
  text-align: center;
}

.data-access-form {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .data-access-form, .data-access-form-container p {
    width: 40%;
  }
}

.data-access-form input,
.data-access-form select,
.data-access-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #333;
  color: #fff;
}

.data-access-form textarea {
  height: 100px;
}

.data-access-form input:focus,
.data-access-form textarea:focus,
.data-access-form select:focus {
  background-color: #444; 
  outline: none; 
}

.radio-group .radio-item,
.checkbox-group .checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.radio-group .radio-item input[type="radio"],
.checkbox-group .checkbox-item input[type="checkbox"] {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.radio-group .radio-item label,
.checkbox-group .checkbox-item label {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  padding-left: 30px; /* Space for the custom control */
  cursor: pointer;
}

.radio-group .radio-item label:before,
.checkbox-group .checkbox-item label:before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.radio-group .radio-item input[type="radio"]:checked + label:before,
.checkbox-group .checkbox-item input[type="checkbox"]:checked + label:before {
  border-color: #502D6D;
  background-color: #502D6D;
}

.radio-group .radio-item label:before {
  border-radius: 50%; /* Make the radio button round */
}

.checkbox-group .checkbox-item label:before {
  border-radius: 3px; /* Keep checkboxes square */
}

.data-access-form label {
  margin-bottom: 5px;
}

.data-access-form .submit-button {
  width: 100%;
  padding: 15px;
  background-color: #502D6D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 50px;
  transition: background-color 0.3s, color 0.3s;
}

.data-access-form .submit-button:disabled {
  background-color: #25272a;
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none; 
}

.validation-error {
  color: red;
  margin-top: 5px;
}
