/* SignupPage.css */
.signup-form-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the items for better control over child positioning */
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 5vh; /* Padding from top */
  background-color: #1b1b21;
  color: #fff;
  font-family: Montserrat, sans-serif;
}

.signup-text-container, form {
  width: 90%; /* Set the same width for both the text container and form */
  margin: 0 auto; /* Center both elements horizontally */
  text-align: left;
}

@media only screen and (min-width: 600px) {
  .signup-text-container, form {
    width: 40%;
  }
}

.signup-text-container {
  margin-bottom: 20px; /* Space between text container and form */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the start */
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input, textarea {
  width: 100%; /* Make inputs take the full width of the form */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

}

textarea {
  height: 100px;;
}

.email-button {
  width: 100%; /* Full width for consistency */
  padding: 15px;
  background-color: #502D6D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;  
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .email-button {
    margin-bottom: 1rem;
  }
}

