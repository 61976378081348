header {
    background-color: #25272a;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center; /* Centers the header container */
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
    display: flex;
    align-items: center; /* Vertically centers items */
    justify-content: space-between; /* Distributes space between items */
    width: 100%;
    height: 10%;
    max-width: 95%;
}

@media only screen and (min-width: 600px) {
    .header-container {
      max-width: 66%;
    }
  }

.logo img {
    height: 4.5em;
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.menu li {
    margin-left: 20px;
}

.menu li a {
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    display: inline-block;
}

.menu li a:hover {
    color: #502D6D;
}

.start-for-free-button {
    display: inline-block; /* Makes the link behave like a button */
    padding: 10px 20px;
    background-color: #502D6D; /* Example background color */
    color: white;
    text-decoration: none; /* Removes underline from links */
    border-radius: 30px;
    text-align: center;
    cursor: pointer; /* Cursor like a button */
    border: 2px solid transparent; /* Invisible border to keep size consistent */
    margin: 10px; /* Adjust spacing as needed */
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.start-for-free-button:hover {
    border-color: #502D6D; /* Change border color on hover */
    background-color: rgba(37, 39, 42, 0.7);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
}
