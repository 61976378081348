.App {
  font-family: Arial, sans-serif;
  background-color: #1b1b21;
  color: white;
  padding-top: 70px; /* Padding for the header */
  min-height: 100vh; /* Minimum height to fill the viewport */
  box-sizing: border-box; /* Ensures padding is included in the height calculation */
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  overflow: hidden; /* Hides any overflow to prevent scrollbars */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  flex-grow: 1; /* Allows the header to take up necessary space, but not more */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #1b1b21; /* Ensures footer blends with app background */
  position: fixed; /* Keeps footer at bottom */
  bottom: 0; /* Aligns footer at the bottom of the viewport */
  left: 0;
}

.privacy-policy-link {
  color: #fff; /* Assuming white text for visibility */
  text-decoration: none; /* Removes underline */
  font-family: Montserrat, sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
