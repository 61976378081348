.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1b1b21;
  color: #d1d1d1; /* Light text color for readability */
}

.privacy-container {
  flex: 1;
  max-width: 66%;
}

/* Link Styles */
a {
  color: #66b2ff; /* Softer blue for links */
  text-decoration: none; /* Remove underline */
}

a:hover {
  color: #3399ff; /* Slightly brighter blue on hover */
  text-decoration: underline; /* Underline on hover */
}

/* Additional styles for headings and paragraphs */
h1, h2, h3, p {
  color: #d1d1d1; /* Ensure text is readable */
}

/* Specific styles for bold text */
b {
  color: #ffffff; /* Make bold text stand out */
}

/* Specific styles for italic text */
i {
  color: #d1d1d1; /* Make italic text readable */
}
